@coral: #ff983e;

* {
    margin: 0;
    padding: 0;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

html, body, main, div, nav, ul, li, a, p {
    display: block;
}

html, body, main, div { 
    color: white;
    background-color: black;
}

a {
    color: inherit;
    text-decoration: none;
    ::after {
        color: inherit;
    }
    ::hover {
        color: #bbb;
        border-radius: 2rem;
        box-shadow: 10px 5px 5px #3b3b3b;
    }
}

nav {
    background-color: #161616;
    margin-bottom: 1rem;
    border-bottom: @coral 0.1rem solid;
    * {
        background-color: #161616;
    }
}

nav#nav-top-max {
    padding: 0.5rem 0;
    div#nav-links {
        display: flex;
        flex: auto;
    }   
    ul {
        flex: 1;
        color: #eceae2;
        display: flex;
        li {
            padding: 0 4rem;
        }
    }

}

#left-side, #right-side {
    * {
        color: #444;
        background-color: #bbb;
    }
}

#main-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    grid-template-areas: "left-side main right-side";
}

iframe {
    width: 80vw;
    height: 40vw;
    margin: 4rem auto;
}

#nav-top-min {
    display: none;
    text-align: center;
    li {
        border-bottom: @coral 0.1rem solid;
    }
}

@media screen {
    @media (max-width: 600px) {
        #nav-top-max {
            display: none;
        }
        #nav-links {
            display: none;
        }
        #nav-top-min {
            display: block;
        }
        #main-grid {
            grid-template-areas: "main";
        }
    }
}



